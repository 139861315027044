<script setup>
import Login from "./Login.vue";
import CustomerType from "./CustomerType.vue";
import LoadingThingy from "./LoadingThingy.vue";
import CustomerSurveyForm from "./CustomerSurveyForm.vue";
import InternalSurveyForm from "./InternalSurveyForm.vue";
import EnterpriseSurveyForm from "./EnterpriseSurveyForm.vue";
import { checkChatbotEnabled } from "../utils/commonRules";

const props = defineProps({
  hashKey: String,
  isLoading: Boolean,
  startChat: Function,
  isInternal: Boolean,
  isExternal: Boolean,
  newCustomer: Function,
  isEnterprise: Boolean,
  isNewCustomer: Boolean,
  channelOptions: Object,
  isInternalChat: Boolean,
  initializeError: String,
  isZiplyCustomer: Boolean,
  preChatQuestions: Object,
  isExistingCustomer: Boolean,
  completeLoginFlow: Function,
  foundCustomerSession: Boolean,
  existingCustomerFlow: Function,
  preChatSurveyResponses: Object,
});
const isChatBotEnabled = checkChatbotEnabled();
</script>

<template>
  <template v-if="isExternal && isChatBotEnabled">
    <!-- LOADING INDICATOR PAGE -->
    <div
      v-if="isLoading"
      class="zf-w-full zf-h-full zf-flex zf-justify-center zf-items-center"
    >
      <LoadingThingy class="!zf-fill-blue-500" />
    </div>
    <!-- LOGIN PAGE -->
    <div
      v-if="(isZiplyCustomer && foundCustomerSession) || isExistingCustomer"
      class="zf-flex zf-h-full zf-flex-col zf-justify-center zf-items-center"
    >
      <!-- PASS prechatSurveyResponse as prop -->
      <Login
        :is-ziply-customer="isZiplyCustomer"
        :found-customer-session="foundCustomerSession"
        :is-existing-customer="isExistingCustomer"
        :new-customer="newCustomer"
        :initializeError="initializeError"
        :complete-login-flow="completeLoginFlow"
        :hash-key="hashKey"
        id="loginFrame"
      />
    </div>
    <!-- SURVEY FORM PAGE -->
    <div
      v-if="(!isZiplyCustomer && foundCustomerSession) || isNewCustomer"
      class="zf-flex zf-h-full zf-flex-col zf-justify-center zf-items-center"
    >
      <div class="!zf-p-4 !zf-h-full !zf-overflow-scroll zf-no-scrollbar">
        <div
          class="!zf-font-bold !zf-mb-2 zf-text-right"
          @click="existingCustomerFlow"
          v-if="!isLoading"
        >
          <a class="zf-link zf-cursor-pointer"> I am an existing customer </a>
        </div>
        <p class="!zf-pb-8" v-if="!isLoading">
          Your privacy is important to us. We will not ask for SSN, or other
          personal information. To pay online, please go to MyAccount (free) or
          use Pay by Phone ($3.50 fee) at
          <a class="zf-link" href="tel:866-699-4759">866-699-4759</a>. If you
          need to cancel service, please call
          <a class="zf-link" href="tel:866-699-4759">866-699-4759</a>. To
          proceed with this chat, please complete the following and click
          <strong>Start Chat</strong>.
        </p>
        <p
          class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0"
          v-if="initializeError"
        >
          {{ initializeError }}
        </p>
        <CustomerSurveyForm
          :channel-options="channelOptions"
          :startChat="startChat"
          :preChatSurveyResponses="preChatSurveyResponses"
          :preChatQuestions="preChatQuestions"
          :existing-customer-flow="existingCustomerFlow"
          :is-loading="isLoading"
          :is-internal="isInternal"
          v-if="channelOptions && !isLoading"
        />
      </div>
    </div>
    <!-- CUSTOMERF TYPE SCREEN -->
    <div
      class="zf-flex zf-h-full zf-flex-col zf-justify-center zf-items-center"
      v-if="!foundCustomerSession && !isZiplyCustomer"
    >
      <CustomerType
        :new-customer="newCustomer"
        :existing-customer-flow="existingCustomerFlow"
      />
    </div>
  </template>

  <!-- Survey form for non chatbot -->
  <template v-else-if="isExternal && !isChatBotEnabled">
    <div
      class="zf-flex zf-h-full zf-flex-col zf-justify-center zf-items-center"
    >
      <div class="!zf-p-4 !zf-h-full">
        <p class="!zf-pb-8" v-if="!isLoading && !initializeError">
          Your privacy is important to us. We will not ask for SSN, or other
          personal information. To pay online, please go to MyAccount (free) or
          use Pay by Phone ($3.50 fee) at
          <a class="zf-link" href="tel:866-699-4759">866-699-4759</a>. If you
          need to cancel service, please call
          <a class="zf-link" href="tel:866-699-4759">866-699-4759</a>. To
          proceed with this chat, please complete the following and click
          <strong>Start Chat</strong>.
        </p>
        <p
          class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0"
          v-if="initializeError"
        >
          {{ initializeError }}
        </p>
        <LoadingThingy v-if="isLoading" class="!zf-fill-blue-500" />
        <CustomerSurveyForm
          :channel-options="channelOptions"
          :startChat="startChat"
          :preChatSurveyResponses="preChatSurveyResponses"
          :preChatQuestions="preChatQuestions"
          :existing-customer-flow="existingCustomerFlow"
          :is-loading="isLoading"
          :is-internal="isInternal"
          v-if="!isLoading && !initializeError"
        />
      </div>
    </div>
  </template>

  <!-- Survey form for Enterprise Chat -->

  <template v-else-if="isEnterprise">
    <div
      class="zf-flex zf-h-full zf-flex-col zf-justify-center zf-items-center !zf-m-4"
    >
      <p
        class="!zf-mb-2 !zf-text-justify"
        v-if="!isLoading && !initializeError"
      >
        Thank you for your interest in Ziply Fiber. We’re here to assist you as
        best we can. Please enter your information below to get started. Chat is
        available Monday-Friday, 8am-5pm PST. Please call
        <a class="zf-link" href="tel:1-888-688-0016">1-888-688-0016</a> for
        technical support or customer care.
      </p>
      <p
        class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0"
        v-if="initializeError"
      >
        {{ initializeError }}
      </p>
      <LoadingThingy v-if="isLoading" class="!zf-fill-blue-500" />
      <EnterpriseSurveyForm
        :channel-options="channelOptions"
        :startChat="startChat"
        :preChatSurveyResponses="preChatSurveyResponses"
        :preChatQuestions="preChatQuestions"
        :is-loading="isLoading"
        v-if="!isLoading"
      />
    </div>
  </template>

  <!-- Survey form for Internal Chat -->
  <template v-else-if="isInternalChat">
    <div class="zf-h-full zf-flex zf-flex-col !zf-items-center">
      <p
        class="!zf-mb-2 !zf-text-justify"
        v-if="!isLoading && !initializeError"
      >
        To proceed with this chat, please complete the following and click
        <strong>Start Chat</strong>.
      </p>
      <p
        class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0"
        v-if="initializeError"
      >
        <span class="zf-whitespace-pre-wrap">
          {{ initializeError }}
        </span>
      </p>
      <LoadingThingy v-if="isLoading" class="!zf-fill-blue-500" />
      <InternalSurveyForm
        :start-chat="startChat"
        v-if="!initializeError && !isLoading"
        :pre-chat-survey-responses="preChatSurveyResponses"
        :pre-chat-questions="preChatQuestions"
        :is-loading="isLoading"
        :is-internal="isInternal"
      />
    </div>
  </template>
</template>
