import LiveWorkItemState from "@microsoft/omnichannel-chat-sdk/lib/core/LiveWorkItemState";
import {
  EVENTS,
  MESSAGES,
  CHAT_CHANNEL,
  departmentMapping,
  EXCEPTION_MESSAGE,
} from "../constants";
import {
  fillingPhone,
  scrollToBottom,
  buildDataToStartChat,
  extractDataFromMyAccount,
  addAgentAvailabilityToChannel,
  cleanFieldsAndValidations,
  clearSessionStore,
  extractDataFromIDC,
  checkChatbotEnabled,
} from "./commonRules";
import {
  postChatExceptionsToKafkaTopic,
  postChatUserActionsToKafkaTopic,
} from "./kafkaLogs";
const currentEnv = import.meta.env.VITE_ENV;
import { runSetupBeforeChat } from "./loginFlow";
const agentInfoUrl = import.meta.env.VITE_CONVERSATION_AGENT_INFO_URL;
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const getChatAllowedOnPaths = async (isMobileApp, allowChat) => {
  const origin = window.location.origin;
  const hostname = window.location.hostname;
  const currentPath = window.location.pathname;
  try {
    let getChatPathsCall = null;
    if (!isMobileApp && hostname !== "localhost") {
      getChatPathsCall = await fetch(`${origin}/helpcenter/GetChatPaths`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
    const getChatPathsResponse = getChatPathsCall
      ? await getChatPathsCall.json()
      : {};
    const allowedChatPaths = getChatPathsResponse.allowChatOn ?? [];
    if (isMobileApp) {
      allowChat.value = true;
      console.log(`Chat allowed for my account mobile app`);
    } else if (currentPath.includes("/sales")) {
      allowChat.value = true;
      console.log(`Chat allowed value for ${currentPath}: ${allowChat.value}`);
    } else if (currentPath.includes("/myaccount")) {
      allowChat.value = true;
      console.log(`Chat allowed value for ${currentPath}: ${allowChat.value}`);
    } else if (allowedChatPaths.length > 0) {
      allowChat.value =
        allowedChatPaths.some((path) => currentPath === path) ||
        currentPath === "/";
      console.log(`Chat allowed value for ${currentPath}: ${allowChat.value}`);
    } else {
      allowChat.value = true;
      console.log(`Chat allowed value for ${currentPath}: ${allowChat.value}`);
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateQueues = (
  queues,
  utc_now,
  offset,
  salesLocation,
  chatSDK,
  salesAgentsAvailable,
  preChatSurveyResponses
) => {
  const _queues = queues?.map((item) => {
    item = addAgentAvailabilityToChannel(item, utc_now, offset);
    if (item.name === "Sales" && salesLocation && !chatSDK?.chatToken?.chatId) {
      salesAgentsAvailable.value = !item.isUnAvailable;
    }
    if (
      preChatSurveyResponses.value.channel_id === item.name &&
      item.isUnAvailable
    ) {
      preChatSurveyResponses.value.channel_id = "";
    }
    return item;
  });
  return _queues;
};

export const loadChannelOptions = async (
  manualTrigger = false,
  offset,
  salesLocation,
  chatSDK,
  salesAgentsAvailable,
  preChatSurveyResponses,
  channelOptions
) => {
  const url = import.meta.env.VITE_CHANNEL_OPTIONS_URL;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (data?.queues) {
    if (salesLocation && !chatSDK?.chatToken?.chatId && !manualTrigger) {
      const salesChannelDetails = data?.queues?.find(
        (channel) => channel.name === "Sales"
      );
      const salesChannelAvailability =
        addAgentAvailabilityToChannel(salesChannelDetails);
      salesAgentsAvailable.value = !salesChannelAvailability?.isUnAvailable;
    }
    const updatedQueues = updateQueues(
      data?.queues,
      data?.utc_now,
      offset.value,
      salesLocation,
      chatSDK,
      salesAgentsAvailable,
      preChatSurveyResponses
    );
    channelOptions.value = {
      utc_now: data.utc_now,
      queues: updatedQueues,
    };
  } else {
    console.log("Error in fetching channel options");
  }
  return channelOptions.value;
};

export const handleCancelEndChat = async (
  endChat,
  showChatWidget,
  emailTranscript,
  confirmCloseChat,
  inactivityTimeout,
  showPostChatSurvey,
  confirmDecisionState,
  preChatSurveyResponses,
  type
) => {
  if (sessionStorage.getItem("timed-out") === "true") {
    showPostChatSurvey.value = false;
    showChatWidget.value = true;
    confirmCloseChat.value = true;
    if (CHAT_CHANNEL.INTERNAL === type) {
      const data = extractDataFromIDC();
      const email = data.state.user.email;
      emailTranscript.value = email ? email.toLowerCase().trim() : "";
    } else {
      emailTranscript.value = preChatSurveyResponses.value.email
        ? preChatSurveyResponses.value.email.trim()
        : "";
    }
    await endChat(false, true);
    return;
  }
  inactivityTimeout.value = "";
  confirmCloseChat.value = false;
  confirmDecisionState.value = false;
};

export const getOldChatMessages = async (
  chatSDK,
  preChatSurveyResponses,
  channelOptions,
  convId,
  type
) => {
  try {
    const oldMessages = await chatSDK.getMessages();
    if (!Array.isArray(oldMessages)) {
      throw new Error(JSON.stringify(oldMessages));
    } else {
      return {
        messages: oldMessages,
        startedAt: new Date(),
      };
    }
  } catch (e) {
    console.log(e);
    try {
      postChatExceptionsToKafkaTopic(
        EVENTS.ERROR,
        type,
        preChatSurveyResponses.value,
        channelOptions.value,
        e,
        convId.value,
        EXCEPTION_MESSAGE.FAILED_TO_FETCH_MESSAGES
      );
    } catch (e) {
      console.log(e);
      return {
        messages: [],
        startedAt: new Date(),
      };
    }
    return {
      messages: [],
      startedAt: new Date(),
    };
  }
};

export const clearSurvey = (showQualtricsSurvey) => {
  var qualtricsSurvey = document.getElementById("qualtricsSurvey");
  if (qualtricsSurvey) {
    qualtricsSurvey.style.display = "none";
    if (qualtricsSurvey.childNodes.length > 0) {
      qualtricsSurvey.removeChild(qualtricsSurvey.childNodes[0]);
    }
    showQualtricsSurvey.value = false;
  }
};

export const getConversationData = async (chatSDK, chatMessages) => {
  let conversationDetails = {};
  let conversationList = [];
  if (chatSDK?.chatToken?.chatId) {
    conversationDetails = await chatSDK.getConversationDetails();
    conversationList = chatMessages.value.map((message) => {
      if (message.sender.displayName === "Customer") {
        return {
          CUSTOMER: message.content,
        };
      } else {
        return {
          AGENT: message.content,
        };
      }
    });
  }
  return {
    conversationDetails,
    conversationList,
  };
};

const fetchAgentDetaials = async (conversationId) => {
  const getAgentDetails = await fetch(
    agentInfoUrl +
      "?" +
      new URLSearchParams({
        conversation_id: conversationId,
      }),
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return await getAgentDetails.json();
};

export const buildSurveyUrl = async (
  preChatSurveyResponses,
  chatSDK,
  convId,
  chatMessages,
  type
) => {
  const channelId = preChatSurveyResponses.value.channel_id;
  const customerName = preChatSurveyResponses.value.name || "";
  const customerEmail = preChatSurveyResponses.value.email || "";
  const customerPhone = preChatSurveyResponses.value.phone || "";
  let finalCrisId = "";
  let finalNickName = "";
  let finalCenterName = "";
  let conversationNumber = "";
  let finalConversationId = "";
  const location =
    type === CHAT_CHANNEL.EXTERNAL
      ? departmentMapping.find((dept) => dept.dept === channelId)?.deptId ||
        channelId ||
        ""
      : "Enterprise";
  if (chatSDK?.chatToken?.chatId) {
    if (convId.value === "" || convId.value === undefined) {
      const { conversationDetails } = await getConversationData(
        chatSDK,
        chatMessages
      );
      convId.value = conversationDetails.conversationId;
    }
    let agentDetails = null;
    if (convId.value) {
      agentDetails = await fetchAgentDetaials(convId.value);
    }
    let { nickname, center_name, cris_id, conversation_number } = agentDetails;
    finalCrisId = cris_id ? cris_id : "";
    finalNickName = nickname ? nickname : "";
    finalCenterName = center_name ? center_name : "";
    finalConversationId = convId.value ? convId.value : "";
    conversationNumber = conversation_number ? conversation_number : "";
  }
  return `https://ziplyfiber.sjc1.qualtrics.com/jfe/form/SV_eqXCFSpkf31O68d?location=${location}&TriggeredAt=${new Date().toISOString()}&CustomerName=${customerName}&CustomerEmail=${customerEmail}&CustomerPhone=${customerPhone}&agent_id=${finalCrisId}&ChatID=${finalConversationId}&Contact%20Center%20Name=${finalCenterName}&ConversationNumber=${conversationNumber}&nickname=${finalNickName}&env=${currentEnv}`;
};

export const requestEmailTranscript = async (
  convId,
  chatSDK,
  chatMessages,
  channelOptions,
  transcriptSent,
  emailTranscript,
  confirmCloseChat,
  transcriptSending,
  showPostChatSurvey,
  showQualtricsSurvey,
  transcriptSentFailed,
  type,
  preChatSurveyResponses
) => {
  const { conversationDetails, conversationList } = await getConversationData(
    chatSDK,
    chatMessages
  );
  if (!emailPattern.test(emailTranscript.value)) {
    transcriptSentFailed.value = "Please enter a valid email.";
    return;
  }
  try {
    transcriptSending.value = true;
    transcriptSentFailed.value = "";
    await chatSDK.emailLiveChatTranscript({
      emailAddress: emailTranscript.value,
      attachmentMessage: "Your Chat Transcript",
    });
    transcriptSent.value = true;
    setTimeout(() => {
      transcriptSent.value = false;
    }, 10000);
    try {
      postChatUserActionsToKafkaTopic(
        EVENTS.TRANSCRIPTS,
        type,
        channelOptions.value,
        preChatSurveyResponses.value,
        convId.value,
        conversationDetails
      );
    } catch (e) {
      console.log(e);
    } finally {
      try {
        postChatUserActionsToKafkaTopic(
          EVENTS.END,
          type,
          channelOptions.value,
          preChatSurveyResponses.value,
          convId.value,
          conversationDetails,
          conversationList
        );
      } catch (e) {
        console.log(e);
      } finally {
        await chatSDK.endChat();
        clearSessionStore(type);
        transcriptSending.value = false;
        confirmCloseChat.value = true;
        showPostChatSurvey.value = true;
        showQualtricsSurvey.value = false;
      }
    }
  } catch (e) {
    console.log(e);
    transcriptSending.value = false;
    try {
      postChatExceptionsToKafkaTopic(
        EVENTS.ERROR,
        type,
        preChatSurveyResponses.value,
        channelOptions.value,
        e,
        convId.value,
        EXCEPTION_MESSAGE.EMAIL_TRANSCRIPT
      );
    } catch (e) {
      console.log(e);
    }
    transcriptSent.value = false;
    transcriptSending.value = false;
    transcriptSentFailed.value = "Unable to email transcript.";
  }
};

export const handleEndChat = async (
  confirmed = false,
  onInactive = false,
  convId,
  cleanUp,
  chatSDK,
  surveyUrl,
  chatMessages,
  salesLocation,
  showChatWidget,
  emailTranscript,
  initiatingSurvey,
  confirmCloseChat,
  inactivityTimeout,
  showPostChatSurvey,
  confirmDecisionState,
  preChatSurveyResponses,
  type
) => {
  emailTranscript.value = preChatSurveyResponses.value.email
    ? preChatSurveyResponses.value.email.trim()
    : "";
  if (convId.value === "" && chatSDK?.chatToken?.chatId) {
    const { conversationDetails } = getConversationData(chatSDK, chatMessages);
    if (conversationDetails === undefined) return;
    convId.value = conversationDetails.conversationId;
  }
  if (!confirmed) {
    if (chatSDK?.chatToken?.chatId && convId.value !== "") {
      initiatingSurvey.value = true;
      surveyUrl.value = await buildSurveyUrl(
        preChatSurveyResponses,
        chatSDK,
        convId,
        chatMessages,
        type
      );
      initiatingSurvey.value = false;
    }
  }
  if (onInactive) {
    inactivityTimeout.value = MESSAGES.INACTIVITY_TIMEOUT;
  }
  if (!salesLocation) {
    preChatSurveyResponses.value.channel_id = "";
  }
  if (chatMessages.value.length === 0) {
    showChatWidget.value = false;
    cleanUp();
    return;
  }
  confirmCloseChat.value = true;
  confirmDecisionState.value = true;
  if (confirmed) {
    showPostChatSurvey.value = true;
  }
};

export const agentEndedSession = async (
  type,
  convId,
  chatSDK,
  surveyUrl,
  chatMessages,
  sendingMessage,
  channelOptions,
  confirmCloseChat,
  showPostChatSurvey,
  preChatSurveyResponses
) => {
  sendingMessage.value = true;
  setTimeout(async () => {
    const { conversationDetails, conversationList } = await getConversationData(
      chatSDK,
      chatMessages
    );
    try {
      surveyUrl.value = await buildSurveyUrl(
        preChatSurveyResponses,
        chatSDK,
        convId,
        chatMessages,
        type
      );
      if (conversationDetails.state === LiveWorkItemState.Active) {
        await chatSDK.endChat();
        clearSessionStore(type);
      }
      postChatUserActionsToKafkaTopic(
        EVENTS.END,
        type,
        channelOptions.value,
        preChatSurveyResponses.value,
        convId.value,
        conversationDetails,
        conversationList,
        "AGENT"
      );
    } catch (e) {
      console.log(e);
    }
    confirmCloseChat.value = true;
    showPostChatSurvey.value = true;
  }, 10000);
};

export const checkAndSyncMessages = (
  chatMessages,
  sendingMessage,
  waiting_to_start,
  type
) => {
  if (type !== CHAT_CHANNEL.INTERNAL) {
    if (chatMessages.value.length === 0) {
      chatMessages.value = [
        {
          id: "00000000-0000-0000-0000-000000000000",
          content: "Connecting...",
          sender: {
            displayName: "",
          },
          timestamp: new Date().toISOString(),
        },
      ];
      sendingMessage.value = true;
      waiting_to_start.value = setTimeout(() => {
        chatMessages.value = [];
        if (chatMessages.value.length === 0) {
          chatMessages.value = [
            {
              id: "00000000-0000-0000-0000-000000000001",
              content: "Please wait for an agent to join the chat.",
              sender: {
                displayName: "",
              },
              timestamp: new Date().toISOString(),
            },
          ];
        }
      }, 5000);
    }
    scrollToBottom();
  }
};

export const handleCloseChatWindow = async (
  convId,
  chatSDK,
  hashKey = "",
  cleanUp,
  startChat,
  showChatWidget,
  preChatQuestions,
  endChat = true,
  fingerPrint,
  chatMessages,
  salesLocation,
  isNewCustomer,
  channelOptions,
  transcriptSent,
  endChatLoading,
  isZiplyCustomer,
  confirmCloseChat,
  clearSessionStore,
  showPostChatSurvey,
  showQualtricsSurvey,
  foundCustomerSession,
  preChatSurveyResponses,
  type
) => {
  endChatLoading.value = true;
  const { conversationDetails, conversationList } = await getConversationData(
    chatSDK,
    chatMessages
  );
  try {
    if (salesLocation) {
      preChatSurveyResponses.value.channel_id = "";
    }
    if (endChat && !transcriptSent.value && chatSDK?.chatToken?.chatId) {
      if (convId.value !== "") {
        try {
          postChatUserActionsToKafkaTopic(
            EVENTS.END,
            type,
            channelOptions.value,
            preChatSurveyResponses.value,
            convId.value,
            conversationDetails,
            conversationList
          );
        } catch (e) {
          console.log(e);
        } finally {
          await chatSDK.endChat();
          clearSessionStore(type);
          endChatLoading.value = false;
          confirmCloseChat.value = true;
          showPostChatSurvey.value = true;
          showQualtricsSurvey.value = false;
        }
      }
      transcriptSent.value = false;
    } else {
      cleanUp();
      if (CHAT_CHANNEL.EXTERNAL === type && !endChat) {
        if (
          window.location.pathname.includes("/myaccount") ||
          window.origin.includes("ziplyfiber.com")
        ) {
          startChatIfUserLoggedIn(
            startChat,
            showChatWidget,
            preChatQuestions,
            preChatSurveyResponses
          );
        } else {
          runSetupBeforeChat(
            hashKey,
            fingerPrint,
            isNewCustomer,
            isZiplyCustomer,
            foundCustomerSession,
            preChatSurveyResponses
          );
        }
      }
      endChatLoading.value = false;
    }
  } catch (e) {
    console.log(e);
    try {
      postChatExceptionsToKafkaTopic(
        EVENTS.ERROR,
        type,
        preChatSurveyResponses.value,
        channelOptions.value,
        e,
        convId.value,
        EXCEPTION_MESSAGE.CLOSE_CHAT_WINDOW,
        conversationDetails
      );
    } catch (e) {
      console.log(e);
    }
    endChatLoading.value = false;
  }
};

export const handleStartNewChat = async (
  cleanUp,
  hashKey,
  startChat,
  toggleChat,
  fingerPrint,
  isNewCustomer,
  isZiplyCustomer,
  preChatQuestions,
  foundCustomerSession,
  salesLocation,
  showChatWidget,
  preChatSurveyResponses,
  type
) => {
  if (salesLocation === true) {
    preChatSurveyResponses.value.channel_id = "Sales";
  }
  await cleanUp();
  if (
    window.location.pathname.includes("/myaccount") ||
    window.origin.includes("ziplyfiber.com")
  ) {
    startChatIfUserLoggedIn(
      startChat,
      showChatWidget,
      preChatQuestions,
      preChatSurveyResponses
    );
    return;
  }
  if (CHAT_CHANNEL.EXTERNAL === type && checkChatbotEnabled()) {
    await runSetupBeforeChat(
      hashKey,
      fingerPrint,
      isNewCustomer,
      isZiplyCustomer,
      foundCustomerSession,
      preChatSurveyResponses
    );
    setTimeout(() => {
      salesLocation ? startChat() : toggleChat();
    }, 400);
  } else {
    setTimeout(() => {
      salesLocation ? startChat() : toggleChat();
    }, 400);
  }
};

export const handleCleanup = (
  chatId,
  agentName,
  surveyUrl,
  clearSurvey,
  closedExtra,
  chatMessages,
  labelToIdMap,
  messageToSend,
  salesLocation,
  offlineTimeout,
  sendingMessage,
  showChatWidget,
  emailTranscript,
  sendMessageError,
  confirmCloseChat,
  initiatingSurvey,
  inactivityTimeout,
  isExistingCustomer,
  showPostChatSurvey,
  showQualtricsSurvey,
  confirmDecisionState,
  preChatSurveyResponses,
  type
) => {
  if (CHAT_CHANNEL.EXTERNAL === type && checkChatbotEnabled()) {
    isExistingCustomer.value = false;
  }
  clearSessionStore(type);
  cleanFieldsAndValidations(preChatSurveyResponses, salesLocation, type);
  clearSurvey(showQualtricsSurvey);
  chatId.value = null;
  agentName.value = null;
  surveyUrl.value = null;
  chatMessages.value = [];
  labelToIdMap.value = {};
  messageToSend.value = "";
  closedExtra.value = false;
  emailTranscript.value = "";
  sendMessageError.value = "";
  offlineTimeout.value = null;
  sendingMessage.value = false;
  showChatWidget.value = false;
  inactivityTimeout.value = "";
  confirmCloseChat.value = false;
  initiatingSurvey.value = false;
  showPostChatSurvey.value = false;
  confirmDecisionState.value = false;
};

export const startChatIfUserLoggedIn = (
  startChat,
  showChatWidget,
  preChatQuestions,
  preChatSurveyResponses
) => {
  const { userInfo, tokenResponse } = extractDataFromMyAccount();
  if (userInfo) {
    preChatSurveyResponses.value.name = userInfo?.name;
    preChatSurveyResponses.value.email = userInfo?.email;
    preChatSurveyResponses.value.phone = fillingPhone(userInfo?.phone);
    preChatSurveyResponses.value.usi = userInfo?.billingUSI;
    preChatSurveyResponses.value.uuidt = tokenResponse?.idToken;
    preChatSurveyResponses.value.uuidr = tokenResponse?.refreshToken;
    preChatSurveyResponses.value.channel_id = "Sales";
    const { chatContext, preChatResponse } = buildDataToStartChat(
      preChatQuestions.value,
      preChatSurveyResponses.value
    );
    showChatWidget.value = true;
    startChat(preChatResponse, chatContext);
  }
};
