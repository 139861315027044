<script setup>
import interact from "interactjs";
import { onMounted, onUnmounted, ref } from "vue";
import {
  OmnichannelChatSDK,
  isCustomerMessage,
} from "@microsoft/omnichannel-chat-sdk";
import {
  ChatBubbleOvalLeftIcon,
  Bars3BottomRightIcon,
  XMarkIcon,
} from "@heroicons/vue/24/solid";

import ChatEnd from "./components/ChatEnd.vue";
import ChatHeader from "./components/ChatHeader.vue";
import ChatFooter from "./components/ChatFooter.vue";
import AllMessages from "./components/AllMessages.vue";
import messsageSound from "./assets/message-received.mp3";
import LoadingThingy from "./components/LoadingThingy.vue";
import ConfirmEndChat from "./components/ConfirmEndChat.vue";
import PreChatScreens from "./components/PreChatScreens.vue";

import {
  postChatExceptionsToKafkaTopic,
  postChatUserActionsToKafkaTopic,
} from "./utils/kafkaLogs";
import {
  buildOOHMessage,
  addAgentAvailabilityToChannel,
  extractDataFromIDC,
  scrollToBottom,
  initializeAudio,
  clearSessionStore,
  typingEvent,
} from "./utils/commonRules";
import {
  EVENTS,
  MESSAGES,
  CHAT_CHANNEL,
  EXCEPTION_MESSAGE,
  UNAVAILABLE_REASON,
  CHATS_AVAILABLE_ON_PATHS,
  departmentMapping,
} from "./constants";
import {
  agentEndedSession,
  checkAndSyncMessages,
  clearSurvey,
  getConversationData,
  getOldChatMessages,
  handleCancelEndChat,
  handleCloseChatWindow,
  requestEmailTranscript,
} from "./utils/businessRules";
import { onOffline, onOnline } from "./utils/internetDisconnectivity";
import { handleFileDownlaod, handleFileUpload } from "./utils/handleFiles";

defineProps({
  isInternal: {
    type: Boolean,
    default: false,
  },
});

const omnichannelConfig = {
  orgUrl: import.meta.env.VITE_ORG_URL,
  orgId: import.meta.env.VITE_ORG_ID,
  widgetId: import.meta.env.VITE_APP_ID,
};

const chatSDKConfig = {
  telemetry: {
    disable: true,
  },
};

const convId = ref("");
const chatId = ref(null);
const isLoading = ref(true);
const isTyping = ref(false);
const agentName = ref(null);
const surveyUrl = ref(null);
const userMuted = ref(false);
const chatMessages = ref([]);
const labelToIdMap = ref({});
const messageToSend = ref("");
const closedExtra = ref(false);
const currentChannel = ref("");
const customerName = ref(null);
const emailTranscript = ref("");
const preChatQuestions = ref([]);
const sendMessageError = ref("");
const offlineTimeout = ref(null);
const endChatLoading = ref(false);
const showChatWidget = ref(false);
const initializeError = ref(null);
const inactivityTimeout = ref("");
const sendingMessage = ref(false);
const transcriptSent = ref(false);
const waiting_to_start = ref(null);
const audioPlayEnabled = ref(false);
const confirmCloseChat = ref(false);
const activatorPosition = ref(null);
const containerPosition = ref(null);
const transcriptSentFailed = ref("");
const transcriptSending = ref(false);
const showPostChatSurvey = ref(false);
const storedPreChatResponse = ref({});
const preChatSurveyResponses = ref({});
const showQualtricsSurvey = ref(false);
const confirmDecisionState = ref(false);
const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);
const showBubbleOnSpecialCondition = ref(null);
const messageNotification = new Audio(messsageSound);
const url = import.meta.env.VITE_AGENT_AND_OOH_INFO_URL;
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

onMounted(async () => {
  isLoading.value = false;
  const channelFromSession = JSON.parse(
    sessionStorage.getItem("MS365CurrentChannel")
  );
  if (channelFromSession) {
    await initializeChat(
      channelFromSession?.id || null,
      Boolean(channelFromSession?.id)
    );
  }
  if (sessionStorage.getItem("timed-out") === "true") {
    if (localStorage.getItem("Authentication")) {
      const data = extractDataFromIDC();
      const email = data.state.user.email;
      emailTranscript.value = email ? email.toLowerCase().trim() : "";
    }
    showChatWidget.value = true;
    confirmCloseChat.value = false;
    confirmDecisionState.value = false;
    showPostChatSurvey.value = true;
    endChat(false, true);
  }
  if (window.innerWidth >= 500) {
    activatorPosition.value = {
      left: windowWidth.value - 100 + "px",
      top: windowHeight.value - 110 + "px",
    };
    containerPosition.value = {
      left: windowWidth.value - 496 + "px",
      top: windowHeight.value - 650 + "px",
    };
  } else {
    activatorPosition.value = { bottom: "0px", right: "0px" };
    containerPosition.value = { top: "45px", left: "0px" };
  }
});

let chatSDK = null;

const closeChatWithConfirmation = async (endIfNotStarted) => {
  const { conversationDetails, conversationList } = await getConversationData(
    chatSDK,
    chatMessages
  );
  try {
    if (chatSDK && chatSDK?.chatToken?.chatId) {
      if (confirm(MESSAGES.END_CHAT_CONFIRMATION) === true) {
        try {
          await postChatUserActionsToKafkaTopic(
            EVENTS.END,
            CHAT_CHANNEL.INTERNAL,
            currentChannel.value,
            storedPreChatResponse.value,
            convId.value,
            conversationDetails,
            conversationList
          );
        } catch (e) {
          console.log(e);
        } finally {
          await chatSDK.endChat();
          cleanUp();
          clearSurvey();
          return true;
        }
      } else {
        return false;
      }
    } else if (endIfNotStarted && !chatSDK?.chatToken?.chatId) {
      cleanUp();
      showChatWidget.value = false;
    }
  } catch (e) {
    isLoading.value = false;
    if (endIfNotStarted && !chatSDK?.chatToken?.chatId) {
      cleanUp();
      showChatWidget.value = false;
    }
    return;
  }
};

const initializeChat = async (widgetId, hasExistingSession = false) => {
  showBubbleOnSpecialCondition.value = CHATS_AVAILABLE_ON_PATHS.includes(
    window.location.pathname
  )
    ? false
    : true;
  isLoading.value = true;
  if (!widgetId) {
    console.log("Widget Id is not provided");
    initializeError.value = MESSAGES.ERROR_INITIATE_CHAT;
    isLoading.value = false;
    return;
  }
  const chatAlreadyExist = await closeChatWithConfirmation().then(
    (result) => result
  );
  if (chatAlreadyExist === false) {
    isLoading.value = false;
    return;
  }
  if (!hasExistingSession) {
    cleanUp();
  }
  const _url =
    url +
    "?" +
    new URLSearchParams({
      app_id: widgetId,
    });
  const channelDetails = await fetch(_url, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.json());
  let { data, utcNow } = channelDetails;
  data = {
    name: data.channelName,
    id: data.channelAppId,
    utcNow,
    ...data,
  };
  currentChannel.value = data;
  const channelWithAvailablility = addAgentAvailabilityToChannel(
    data,
    utcNow,
    0
  );
  if (
    channelWithAvailablility.isUnAvailable &&
    channelWithAvailablility.reason === UNAVAILABLE_REASON.OOH
  ) {
    console.log("Chat Initiated in OOH");
    const oohMessage = buildOOHMessage(data);
    initializeError.value = `${MESSAGES.OOH_MESSAGE} ${oohMessage}`;
    isLoading.value = false;
    showChatWidget.value = showBubbleOnSpecialCondition.value;
    return;
  }
  // Agent Availability Check
  // if (channelWithAvailablility.isUnAvailable && channelWithAvailablility.reason === UNAVAILABLE_REASON.NO_AGENTS) {
  //   initializeError.value = MESSAGES.AGENTS_NOT_AVAILABLE
  //   isLoading.value = false;
  //   showChatWidget.value = true;
  //   return
  // }
  initializeError.value = "";
  chatSDK = new OmnichannelChatSDK(
    {
      ...omnichannelConfig,
      widgetId: widgetId,
    },
    chatSDKConfig
  );
  await chatSDK.initialize();
  preChatQuestions.value = await chatSDK.getPreChatSurvey();
  const checkPreviousSession = sessionStorage.getItem("MS365ChatSession");
  if (checkPreviousSession) {
    try {
      const optionalParams = {};
      optionalParams.liveChatContext = JSON.parse(checkPreviousSession);
      await chatSDK.startChat(optionalParams);
      const { messages } = await getOldChatMessages(
        chatSDK,
        preChatSurveyResponses.value,
        currentChannel.value,
        convId.value,
        CHAT_CHANNEL.INTERNAL
      );
      chatMessages.value = messages;
      await initializeChatEvents();
      showChatWidget.value = showBubbleOnSpecialCondition.value;
      closedExtra.value = true;
      preChatSurveyResponses.value = JSON.parse(
        sessionStorage.getItem("MS365ChatPreChatSurvey")
      );
      for (const key in preChatSurveyResponses.value) {
        let parsedKey = JSON.parse(key).QuestionText;
        let parsedValue = "";
        if (preChatSurveyResponses.value[key].includes("Id")) {
          parsedValue = JSON.parse(preChatSurveyResponses.value[key]).Value;
        } else {
          parsedValue = preChatSurveyResponses.value[key];
        }
        storedPreChatResponse.value[parsedKey] = parsedValue;
      }
      const customer =
        storedPreChatResponse.value[
          "Agent name, CRIS/CORP ID" || "Agent name, CRIS/CORP ID\n"
        ] ?? "";
      if (customer.length > 0) {
        customerName.value = customer.split(",")[0] ?? "";
      }
      const { conversationDetails } = await getConversationData(
        chatSDK,
        chatMessages
      );
      if (sessionStorage.getItem("timed-out") === "true") {
        showPostChatSurvey.value = false;
        showChatWidget.value = true;
        confirmCloseChat.value = true;
        emailTranscript.value = preChatSurveyResponses.value.email
          ? preChatSurveyResponses.value.email.trim()
          : "";
        endChat(false, true);
      }
      if (conversationDetails.conversationId) {
        convId.value = conversationDetails.conversationId;
        await postChatUserActionsToKafkaTopic(
          EVENTS.REFRESH,
          CHAT_CHANNEL.INTERNAL,
          currentChannel.value,
          storedPreChatResponse.value,
          convId.value,
          conversationDetails
        );
      }
      scrollToBottom();
    } catch (e) {
      console.log(e);
      postChatExceptionsToKafkaTopic(
        EVENTS.ERROR,
        CHAT_CHANNEL.INTERNAL,
        storedPreChatResponse.value,
        currentChannel.value,
        e,
        convId.value,
        EXCEPTION_MESSAGE.RESTORE_SESSION
      );
      clearSessionStore(CHAT_CHANNEL.INTERNAL);
    }
  }
  isLoading.value = false;
  showChatWidget.value = showBubbleOnSpecialCondition.value;
};

onUnmounted(() => {
  clearSessionStore(CHAT_CHANNEL.INTERNAL);
});

const toggleChat = async () => {
  initializeAudio(false, audioPlayEnabled, userMuted);
  if (confirmCloseChat.value) return;

  showChatWidget.value = !showChatWidget.value;
  if (showChatWidget.value) {
    isLoading.value = true;
    closedExtra.value = true;
    isLoading.value = false;
    if (chatMessages.value.length > 0) {
      scrollToBottom();
    }
  }
};

const initializeChatEvents = async () => {
  await chatSDK.onNewMessage((message) => {
    if (audioPlayEnabled.value) {
      messageNotification.play();
    }
    if (waiting_to_start.value !== null) {
      clearTimeout(waiting_to_start.value);
      waiting_to_start.value = null;
      chatMessages.value = [];
      sendingMessage.value = false;
    }
    showChatWidget.value = true;
    if (
      chatMessages.value.length === 1 &&
      message.content === chatMessages.value[0].content
    ) {
      return;
    }
    chatMessages.value = [message, ...chatMessages.value];
    scrollToBottom();
    if (!isCustomerMessage(message)) {
      agentName.value = message.sender.displayName;
      chatId.value = chatSDK?.chatToken?.chatId;
    }
  });
  await chatSDK.onTypingEvent(() => {
    typingEvent(isTyping);
  });
  await chatSDK.onAgentEndSession(async () => {
    agentEndedSession(
      CHAT_CHANNEL.INTERNAL,
      convId,
      chatSDK,
      surveyUrl,
      chatMessages,
      sendingMessage,
      currentChannel,
      confirmCloseChat,
      showPostChatSurvey,
      preChatSurveyResponses
    );
    clearSessionStore(CHAT_CHANNEL.INTERNAL);
  });
};

const chatAction = (e) => {
  e.stopPropagation();
  console.warn("CHAT INITIATED");
  toggleChat();
};

const startChat = async (preChatResponse, mapping) => {
  for (const key in preChatResponse) {
    let parsedKey = JSON.parse(key).QuestionText;
    let parsedValue = "";
    if (preChatResponse[key].includes("Id")) {
      parsedValue = JSON.parse(preChatResponse[key]).Value;
    } else {
      parsedValue = preChatResponse[key];
    }
    storedPreChatResponse.value[parsedKey] = parsedValue;
  }
  transcriptSent.value = false;
  labelToIdMap.value = mapping;
  const customer =
    storedPreChatResponse.value[
      "Agent name, CRIS/CORP ID" || "Agent name, CRIS/CORP ID\n"
    ] ?? "";
  if (customer.length > 0) {
    customerName.value = customer.split(",")[0] ?? "";
  }
  preChatResponse = {
    Type: "InputSubmit",
    ...preChatResponse,
  };
  try {
    initializeAudio(true, audioPlayEnabled, userMuted);
    isLoading.value = true;
    const customChatContext = {
      Source: {
        value: window?.location?.href || "Unknown",
        isDisplayable: true,
      },
    };
    await chatSDK.startChat({
      preChatResponse: preChatResponse,
      customContext: customChatContext,
      sendDefaultInitContext: true,
    });
    console.warn("CHAT STARTED");
    const chatContext = await chatSDK.getCurrentLiveChatContext();
    sessionStorage.setItem("MS365ChatSession", JSON.stringify(chatContext));
    sessionStorage.setItem(
      "MS365ChatPreChatSurvey",
      JSON.stringify(preChatSurveyResponses.value)
    );
    sessionStorage.setItem(
      "MS365CurrentChannel",
      JSON.stringify(currentChannel.value)
    );
    await initializeChatEvents();
    checkAndSyncMessages(
      chatMessages,
      sendingMessage,
      waiting_to_start,
      CHAT_CHANNEL.INTERNAL
    );
    await sendMessage(JSON.stringify(storedPreChatResponse.value));
    setTimeout(async () => {
      const { conversationDetails } = await getConversationData(
        chatSDK,
        chatMessages
      );
      if (conversationDetails.conversationId) {
        convId.value = conversationDetails.conversationId;
        await postChatUserActionsToKafkaTopic(
          EVENTS.START,
          CHAT_CHANNEL.INTERNAL,
          currentChannel.value,
          storedPreChatResponse.value,
          convId.value,
          conversationDetails
        );
      }
    }, 10000);
    isLoading.value = false;
  } catch (e) {
    isLoading.value = false;
    if (e.message === "ConversationInitializationFailure") {
      await postChatExceptionsToKafkaTopic(
        EVENTS.ERROR,
        CHAT_CHANNEL.INTERNAL,
        storedPreChatResponse.value,
        currentChannel.value,
        e,
        convId.value,
        EXCEPTION_MESSAGE.CONVERSATION_INITIALIZATION
      );
      initializeError.value = MESSAGES.ERROR_INITIATE_CHAT;
    } else if (e.message === "WidgetUseOutsideOperatingHour") {
      await postChatExceptionsToKafkaTopic(
        EVENTS.ERROR,
        CHAT_CHANNEL.INTERNAL,
        storedPreChatResponse.value,
        currentChannel.value,
        e,
        convId.value,
        EXCEPTION_MESSAGE.WIDGET_OUTSIDE_OPERATING_HOURS
      );
      initializeError.value = MESSAGES.AGENTS_NOT_AVAILABLE;
    }
  }
};

const sendMessage = async (preChatQuestionsData) => {
  sendingMessage.value = true;
  if (preChatQuestionsData) {
    await chatSDK.sendMessage({
      id: "00000000-0000-0000-0000-000000000000",
      content: preChatQuestionsData,
      sender: {
        displayName: "Customer",
      },
      timestamp: new Date().toISOString(),
    });
    sendingMessage.value = false;
    chatMessages.value = [
      {
        id: "00000000-0000-0000-0000-000000000000",
        content: preChatQuestionsData,
        sender: {
          displayName: "Customer",
        },
        timestamp: new Date().toISOString(),
      },
      ...chatMessages.value,
    ];
    return;
  }
  if (messageToSend.value.length === 0) {
    return;
  }
  initializeAudio(false, audioPlayEnabled, userMuted);
  try {
    sendMessageError.value = "";
    await chatSDK.sendMessage({
      content: messageToSend.value,
    });
    chatMessages.value = [
      {
        id: "00000000-0000-0000-0000-000000000000",
        content: messageToSend.value,
        sender: {
          displayName: "Customer",
        },
        timestamp: new Date().toISOString(),
      },
      ...chatMessages.value,
    ];
    sendingMessage.value = false;
    messageToSend.value = "";
    scrollToBottom();
  } catch (e) {
    sendMessageError.value = MESSAGES.SEND_MESSAGE_FAILURE;
    postChatExceptionsToKafkaTopic(
      EVENTS.ERROR,
      CHAT_CHANNEL.INTERNAL,
      storedPreChatResponse.value,
      currentChannel.value,
      e,
      convId.value,
      EXCEPTION_MESSAGE.SEND_MESSAGE
    );
    console.log(e);
    sendingMessage.value = false;
  }
};

const customerTyping = async (event) => {
  if (event.key === "Enter" && messageToSend.value.length > 0) {
    await sendMessage();
  }
};

const endChat = async (confirmed = false, onInactive = false) => {
  if (!confirmed) {
    if (
      window.location.pathname.includes("/idc") ||
      localStorage.getItem("Authentication")
    ) {
      const data = extractDataFromIDC();
      const email = data.state.user.email;
      emailTranscript.value = email ? email.toLowerCase().trim() : "";
    }
    surveyUrl.value = buildSurveyUrl(
      preChatSurveyResponses,
      chatId,
      agentName,
      labelToIdMap
    );
  }
  if (confirmed) {
    preChatSurveyResponses.value = {};
    clearSessionStore(CHAT_CHANNEL.INTERNAL);
  }
  if (onInactive) {
    inactivityTimeout.value = MESSAGES.INACTIVITY_TIMEOUT;
  }
  if (chatMessages.value.length === 0) {
    showChatWidget.value = false;
    return;
  }
  confirmCloseChat.value = true;
  confirmDecisionState.value = true;
  if (confirmed) {
    if (
      window.location.pathname.includes("/idc") ||
      localStorage.getItem("Authentication")
    ) {
      const data = extractDataFromIDC();
      const email = data.state.user.email;
      emailTranscript.value = email ? email.toLowerCase().trim() : "";
    }
    showPostChatSurvey.value = true;
  }
};

const cancelEndChat = () => {
  handleCancelEndChat(
    endChat,
    showChatWidget,
    emailTranscript,
    confirmCloseChat,
    inactivityTimeout,
    showPostChatSurvey,
    confirmDecisionState,
    preChatSurveyResponses,
    CHAT_CHANNEL.INTERNAL
  );
};

const cleanFieldsAndValidations = () => {
  preChatSurveyResponses.value = {};
};

const cleanUp = () => {
  cleanFieldsAndValidations();
  clearSessionStore(CHAT_CHANNEL.INTERNAL);
  chatId.value = null;
  inactivityTimeout.value = "";
  agentName.value = null;
  surveyUrl.value = null;
  chatMessages.value = [];
  labelToIdMap.value = {};
  messageToSend.value = "";
  customerName.value = null;
  currentChannel.value = {};
  closedExtra.value = false;
  emailTranscript.value = "";
  cleanFieldsAndValidations();
  sendMessageError.value = "";
  offlineTimeout.value = null;
  inactivityTimeout.value = "";
  sendingMessage.value = false;
  showChatWidget.value = false;
  confirmCloseChat.value = false;
  showPostChatSurvey.value = false;
  confirmDecisionState.value = false;
};

const emailKeyUp = () => {
  transcriptSentFailed.value = "";
};

const closeChatWindow = async (endChat = true) => {
  if (convId.value !== "") {
    await handleCloseChatWindow(
      convId,
      chatSDK,
      undefined,
      cleanUp,
      startChat,
      showChatWidget,
      preChatQuestions,
      endChat,
      undefined,
      chatMessages,
      false,
      undefined,
      currentChannel,
      transcriptSent,
      endChatLoading,
      undefined,
      confirmCloseChat,
      clearSessionStore,
      showPostChatSurvey,
      showQualtricsSurvey,
      undefined,
      preChatSurveyResponses,
      CHAT_CHANNEL.INTERNAL
    );
  }
};

const startNewChat = async (_endChat = true) => {
  let _currentChannelId = currentChannel?.value?.id;
  cleanUp();
  await initializeChat(_currentChannelId);
};

const downloadFile = (fileMetadata) => {
  handleFileDownlaod(
    convId,
    chatSDK,
    fileMetadata,
    currentChannel,
    CHAT_CHANNEL.INTERNAL,
    preChatSurveyResponses
  );
};

const processUpload = async (evt) => {
  handleFileUpload(
    evt,
    convId,
    chatSDK,
    chatMessages,
    sendingMessage,
    sendMessageError,
    currentChannel,
    CHAT_CHANNEL.INTERNAL,
    preChatSurveyResponses
  );
};

const handleRequestEmailTranscript = () => {
  requestEmailTranscript(
    convId,
    chatSDK,
    chatMessages,
    currentChannel,
    transcriptSent,
    emailTranscript,
    confirmCloseChat,
    transcriptSending,
    showPostChatSurvey,
    showQualtricsSurvey,
    transcriptSentFailed,
    CHAT_CHANNEL.INTERNAL,
    preChatSurveyResponses
  );
};

window.initiateChat = async (secretKey, widgetId) => {
  if (secretKey === "CAB724D7E74F5") {
    await initializeChat(widgetId);
  }
};

window.destroyChat = async (secretKey) => {
  const { conversationDetails, conversationList } = await getConversationData(
    chatSDK,
    chatMessages
  );
  if (secretKey === "CAB724D7E74F5") {
    if (chatSDK && chatSDK?.chatToken?.chatId) {
      await postChatUserActionsToKafkaTopic(
        EVENTS.END,
        CHAT_CHANNEL.INTERNAL,
        currentChannel.value,
        storedPreChatResponse.value,
        convId.value,
        conversationDetails,
        conversationList
      );
    }
    await chatSDK.endChat();
  }
  cleanUp();
};

window.toggleChat = toggleChat;

const buildSurveyUrl = (
  preChatSurveyResponses,
  chatId,
  agentName,
  labelToIdMap
) => {
  labelToIdMap = labelToIdMap.value || {};
  const channelId = preChatSurveyResponses.value.channel_id;
  const agentNameFormatted = agentName.value
    ? `${channelId} -${agentName.value} `
    : "";
  const chatIdFinal = chatId.value ? chatId.value : "";
  const customerName =
    preChatSurveyResponses.value[labelToIdMap["Name"] || ""] || "";
  const customerEmail =
    preChatSurveyResponses.value[labelToIdMap["Email"] || ""] || "";
  const customerPhone =
    preChatSurveyResponses.value[labelToIdMap["Phone Number"] || ""] || "";
  const location =
    departmentMapping.find((dept) => dept.dept === channelId)?.deptId ||
    channelId ||
    "";
  return `https://ziplyfiber.sjc1.qualtrics.com/jfe/form/SV_eqXCFSpkf31O68d?location=${location}&TriggeredAt=${new Date().toISOString()}&CustomerName=${customerName}&CustomerEmail=${customerEmail}&CustomerPhone=${customerPhone}&agent_id=${agentNameFormatted}&ChatId=${chatIdFinal}`;
};

const adjustWidget = () => {
  if (window.innerWidth >= 500) {
    interact("#chatActivator").draggable({
      listeners: {
        move: dragMoveListener,
      },
    });
  }

  function dragMoveListener(event) {
    let target = event.target;
    let x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    let y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
    x = Math.min(Math.max(x, 0), window.innerWidth - target.offsetWidth - 20);
    y = Math.min(Math.max(y, 0), window.innerHeight - target.offsetHeight - 20);
    let chatContainerX, chatContainerY;
    if (x > window.innerWidth / 2) {
      chatContainerX = x - 400;
    } else {
      chatContainerX = x + target.offsetWidth + 20;
    }
    if (y > window.innerHeight / 2) {
      chatContainerY = y - 544;
    } else {
      chatContainerY = y;
    }
    chatContainerX = Math.min(
      Math.max(chatContainerX, 0),
      window.innerWidth - 400
    );
    chatContainerY = Math.min(
      Math.max(chatContainerY, 0),
      window.innerHeight - 544
    );
    const chatContainer = document.getElementById("chatContainers");
    chatContainer.style.position = "fixed";
    containerPosition.value = {
      left: chatContainerX + "px",
      top: chatContainerY + "px",
    };
    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);
    activatorPosition.value = { left: x + "px", top: y + "px" };
  }
};
adjustWidget();

window.addEventListener("resize", () => {
  if (window.innerWidth >= 500) {
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;
    activatorPosition.value = {
      left: windowWidth.value - 100 + "px",
      top: windowHeight.value - 110 + "px",
    };
    containerPosition.value = {
      left: windowWidth.value - 496 + "px",
      top: windowHeight.value - 650 + "px",
    };
    adjustWidget();
  } else {
    interact("#chatActivator").unset();
    activatorPosition.value = { bottom: "0px", right: "0px" };
    containerPosition.value = { top: "45px", left: "0px" };
  }
});

window.ononline = async () => {
  onOnline(
    convId,
    chatSDK,
    endChat,
    chatMessages,
    showChatWidget,
    sendingMessage,
    offlineTimeout,
    currentChannel,
    emailTranscript,
    sendMessageError,
    confirmCloseChat,
    inactivityTimeout,
    showPostChatSurvey,
    preChatSurveyResponses,
    CHAT_CHANNEL.INTERNAL
  );
};

window.onoffline = async () => {
  onOffline(sendingMessage, sendMessageError, offlineTimeout);
};
</script>

<template>
  <div id="chatWrapper" class="chatWrapper" v-if="currentChannel?.id">
    <div
      id="chatActivator"
      :data-x="windowWidth - 96 + 'px'"
      :data-y="windowHeight - 96 + 'px'"
      class="!zf-z-[1501] zf-w-max zf-flex zf-gap-4 zf-justify-end zf-fixed"
      :style="activatorPosition"
    >
      <div
        class="zf-w-12 zf-h-12 zf-rounded-full zf-cursor-pointer zf-shadow-lg hover:zf-bg-ziply-blue-dark !zf-bg-ziply-blue md:zf-w-24 md:zf-h-24 md:zf-rounded-[4px]"
        v-if="!showChatWidget"
        :disaled="isLoading"
        @click="chatAction($event)"
      >
        <LoadingThingy v-if="isLoading" class="!zf-fill-gray-100" />
        <div
          class="!zf-flex zf-w-full !zf-h-full !zf-justify-center !zf-items-center"
          v-if="!isLoading"
        >
          <div class="!zf-text-center">
            <div v-if="!showChatWidget" id="chatBubbleIcon">
              <ChatBubbleOvalLeftIcon
                class="zf-w-6 zf-h-6 md:zf-w-12 md:zf-h-12 !zf-fill-gray-100"
              />
            </div>
            <p
              class="zf-text-3.5 md:zf-text-xl md:zf-flex zf-hidden zf-m-0 zf-text-white"
            >
              Chat
            </p>
          </div>
        </div>
      </div>
      <div
        class="!zf-flex zf-items-center zf-justify-center !zf-px-4 !zf-h-fit !zf-py-2 zf-relative md:zf-flex zf-w-max"
        id="closeBubble"
        v-if="!isLoading && !showChatWidget && showBubbleOnSpecialCondition"
      >
        <button
          class="!zf-fill-white !zf-bg-ziply-blue-dark zf-rounded-full zf-absolute -zf-left-2 -zf-top-2 !zf-p-1"
          @click="closeChatWithConfirmation(true)"
          id="closeWithConfirmation"
        >
          <XMarkIcon class="!zf-w-3 !zf-h-3 zf-fill-white" />
        </button>
      </div>
      <div
        class="zf-w-12 zf-h-12 zf-rounded-full zf-cursor-pointer zf-shadow-lg hover:zf-bg-ziply-blue-dark zf-circle-button md:zf-w-16 md:zf-h-16 !zf-bg-[#000050]"
        v-if="showChatWidget"
        :disaled="isLoading"
      >
        <LoadingThingy v-if="isLoading" class="!zf-fill-gray-100" />
        <div
          class="!zf-flex zf-w-full !zf-h-full !zf-justify-center !zf-items-center"
          v-if="!isLoading"
        >
          <div class="!zf-text-center">
            <div v-if="showChatWidget" id="bars3Icon">
              <Bars3BottomRightIcon
                class="zf-w-6 zf-h-6 md:zf-w-12 md:zf-h-12 !zf-fill-gray-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="chatContainers"
      class="zf-w-max !zf-z-[1500] zf-fixed"
      :style="containerPosition"
    >
      <div
        class="zf-fixed zf-bg-white zf-rounded zf-shadow zf-overflow-hidden !zf-w-12/12 !zf-h-[544px] sm:zf-w-[400px] !sm:zf-h-[546px] xs:!zf-w-full !zf-border !zf-border-white"
        v-if="showChatWidget"
      >
        <div
          class="!zf-h-[9%] !zf-bg-ziply-blue !zf-text-gray-100 !zf-flex !zf-justify-between !zf-w-full !zf-items-center !zf-px-4 !zf-rounded-t"
        >
          <ChatHeader
            :confirm-decision-state="confirmDecisionState"
            :toggle-chat="toggleChat"
            :confirm-close-chat="confirmCloseChat"
            :initiating-survey="false"
            :end-chat="endChat"
            :show-qualtrics-survey="showQualtricsSurvey"
            :show-post-chat-survey="showPostChatSurvey"
            :close-chat-window="closeChatWindow"
            :current-channel="currentChannel"
            :is-internal="true"
          />
        </div>
        <div
          id="MobConvoBody"
          class="!zf-h-[91.2%] !zf-w-full !zf-text-[#3c3c3c] zf-overflow-hidden"
        >
          <div
            id="mobChatBody"
            v-if="chatMessages?.length === 0"
            class="!zf-p-4 !zf-h-full !zf-overflow-scroll zf-no-scrollbar"
          >
            <PreChatScreens
              :is-loading="isLoading"
              :initialize-error="initializeError"
              :start-chat="startChat"
              :pre-chat-survey-responses="preChatSurveyResponses"
              :pre-chat-questions="preChatQuestions"
              :isInternalChat="true"
              :is-internal="isInternal"
              :is-external="false"
            />
          </div>
          <template v-else>
            <div
              class="!zf-flex zf-flex-col !zf-gap-2 !zf-h-full"
              v-if="!confirmCloseChat"
            >
              <AllMessages
                :agentName="agentName"
                :customerName="customerName"
                :chat-messages="chatMessages"
                :send-message="sendMessage"
                :download-file="downloadFile"
                :pre-chat-survey-responses="preChatSurveyResponses"
              />
              <ChatFooter
                :is-typing="isTyping"
                :send-message-error="sendMessageError"
                :message-to-send="messageToSend"
                @update:message-to-send="
                  (newValue) => (messageToSend = newValue)
                "
                :process-upload="processUpload"
                :sending-message="sendingMessage"
                :send-message="sendMessage"
                :customer-typing="customerTyping"
              />
            </div>
            <div
              div
              class="!zf-w-full !zf-h-full !zf-bg-white"
              v-if="confirmCloseChat"
            >
              <div
                v-if="!showPostChatSurvey"
                class="zf-text-czenter zf-flex zf-flex-col zf-items-center zf-justify-center zf-h-full zf-gap-4 zf-px-6"
              >
                <ConfirmEndChat
                  :end-chat-loading="endChatLoading"
                  :inactivity-timeout="inactivityTimeout"
                  :request-email-transcript="handleRequestEmailTranscript"
                  :email-transcript="emailTranscript"
                  @update:email-transcript="
                    (newValue) => (emailTranscript = newValue)
                  "
                  :email-key-up="emailKeyUp"
                  :transcript-sent-failed="transcriptSentFailed"
                  :transcript-sending="transcriptSending"
                  :transcript-sent="transcriptSent"
                  @closeChatWindow="closeChatWindow"
                  @cancelEndChat="cancelEndChat"
                />
              </div>
              <template v-else>
                <ChatEnd
                  :email-transcript="emailTranscript"
                  :show-qualtrics-survey="showQualtricsSurvey"
                  @update:show-qualtrics-survey="
                    (newValue) => (showQualtricsSurvey = newValue)
                  "
                  :transcript-sent="transcriptSent"
                  :start-new-chat="startNewChat"
                  :survey-url="surveyUrl"
                  :is-internal="true"
                  :internal="true"
                  :conv-id="convId"
                  :pre-chat-survey-responses="storedPreChatResponse"
                />
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
